class ProductPopup extends HTMLElement {
  constructor() {
    super();
    this.popup = null;
    this.urls = [];
    const urlObj = new URL(window.location.href);
    this.currentPath = urlObj.pathname + urlObj.search + urlObj.hash;
  }

  connectedCallback() {
    this.popup = this.querySelector('.product-popup');
    this.urls = this.getAttribute('data-popup-urls').split(',');

    // Check local storage to see if popup has been closed or if user has recently viewed a PDP
    if (localStorage.getItem('popupClosed') || (JSON.parse(localStorage.getItem('recently_viewedPDP')) !== null ) ) {
      return;
    }

    // Check if current URL path matches any in the settings
    let showPopup = true;
    for (let url of this.urls) {
      url = url.trim();  // Remove any extra spaces
      try {
        const regex = new RegExp(url);
        if (regex.test(this.currentPath)) {
          showPopup = false;
          break;
        }
      } catch (e) {
        // Not a valid regex, try string comparison
        if (this.currentPath === url) {
          showPopup = false;
          break;
        }
      }
    }

    if (showPopup) {
      this.popup.classList.add('is-active');
    }

    // Close popup
    this.popup.addEventListener('click', () => {
      this.popup.classList.remove('is-active');
    });

    // Set localStorage to prevent popup from showing again
    setTimeout(() => {
      localStorage.setItem('popupClosed', 'true');
    }, 1000)
  }
}

customElements.define('product-popup', ProductPopup);
