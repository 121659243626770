class LanguageSelector extends HTMLElement {
  constructor() {
    super()
  }

  connectedCallback() {
    this.makeFocusable()
    this.addEventListener('click', this.changeLanguage)
  }

  makeFocusable() {
    this.setAttribute('tabindex', '0')
  }

  changeLanguage() {
    const languageForm = document.getElementById('footer-language-form')
    const languageCode = this.getAttribute('data-value')

    if (languageForm && languageCode) {
      localStorage.setItem('languageSelected', true)
      languageForm.return_to.value = window.location
      languageForm.language_code.value = languageCode
      languageForm.submit()
    }
  }
}

customElements.define('language-selector', LanguageSelector)