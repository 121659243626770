class HeaderMiniNav extends HTMLElement {
  constructor() {
    super()

    this.clonedElement = null
  }

  connectedCallback() {
    this.clonedElement = document.createElement('div')
    this.clonedElement.className = this.className + ' is-hidden'
    this.clonedElement.innerHTML = this.innerHTML
    const header = document.querySelector('.main-header')

    header.appendChild(this.clonedElement)

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.boundingClientRect.bottom < 0) {
          this.clonedElement.classList.remove('is-hidden')
        } else {
          this.clonedElement.classList.add('is-hidden')
        }
      })
    })

    observer.observe(this)
  }
}

customElements.define('header-mini-nav', HeaderMiniNav);